.notifRed{
    color: red;
    font-size: 16px;
    text-align: center;
    padding: 15px;
}
.notifSuccess{
    color: rgb(28, 161, 28);
    font-size: 16px;
    text-align: center;
    padding: 15px;
}