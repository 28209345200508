.hodcard1{
    border: 1px solid rgb(7, 7, 7);
    padding: 5px;
    min-width: 18rem;
    max-width: 292px;
    height: 387px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 3px 3px 10px rgb(0, 0, 0);
    transition: all .8s ease ;
    border-radius: 10px;
    /* color: white !important; */

}
.mailid a{
    font-size: .8rem;
    color: #fff;
}
.hodcard1::after{
    content: " ";
    position: absolute;
    top: -100px;
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    transition: all .8s ease ;

}
.hod-cnt1{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 70%;
    position: absolute;
    bottom: -10%;
    /* left: 0; */
    transition: all .8s ease ;
    color: white !important;
    padding-top: 5px;
}
.hod-cnt1 ul{
    display: flex;
    flex-direction: row;
    font-size: 20px;
    background-color: red;

}
.linkedin{
    font-size: 30px;
    color: black;
}

.hod-cnt1 .btn a {
    color: #fff;
}

/* .hod-cnt1 .btn a:hover{
    color: #F5730A;
} */


.hod-img1{
    border-radius: 50% ;
    border: 5px solid #000000;
    
    position: absolute;
    top: 40px;
    overflow: hidden;
    aspect-ratio: 1;
    width: 80%;
    transition: all .8s ease ;

}
.hodcard1:hover .hod-img1{  
    box-shadow: 0 0 0 15rem #cadde4;
    border: 5px solid #1e09da;
    border-radius: 30%;
   
}
.hodcard1:hover{
    /* height: 490px; */
    & .hod-cnt1{
        bottom: 0;
    }
    &::after{
        top: 0;
    }
}

.hod-name1{
    position: absolute;
    top: 282px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.hod-name1 p{
    margin: 0 !important;
    text-align: center;
}