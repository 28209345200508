* {
  box-sizing: border-box;
}
.popupDiv {
  margin-top: 500px;
  margin-left: 200px;
}
.openBtn {
  display: flex;
  justify-content: left;
}
.openButton {
  border: none;
  border-radius: 5px;
  background-color: #1c87c9;
  color: white;
  padding: 14px 20px;
  cursor: pointer;
  position: fixed;
}
.loginPopup {
  position: relative;
  text-align: center;
  width: 100%;
  padding: 30px;
  margin-top: 200px;
}
.formPopup {
  display: none;
  position: fixed;
  left: 45%;
  top: 15%;
  transform: translate(-50%, 5%);
  border: 3px solid #999999;
  z-index: 9;
  background-color: #1c87c9;
}

.formPopup h2{
  color: #fff;
  font-size: 15px;
}
.formContainer {
  max-width: 300px;
  padding: 20px;
  background-color: #fff;
}
.formContainer input[type="text"],
.formContainer input[type="password"] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 20px 0;
  border: none;
  background: #eee;
}
.formContainer input[type="text"]:focus,
.formContainer input[type="password"]:focus {
  background-color: #ddd;
  outline: none;
}
.formContainer .btn {
  padding: 12px 20px;
  border: none;
  background-color: #8ebf42;
  color: #fff;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
  opacity: 0.8;
}
.formContainer .cancel {
  background-color: #cc0000;
}
.formContainer .btn:hover,
.openButton:hover {
  opacity: 1;
}
.popupTextLink {
  color: white;
}
  .popupDiv{
    margin-top: 500px;
    margin-left: 200px;
  }
  .openBtn {
    display: flex;
    justify-content: left;
  }
  .openButton {
    border: none;
    border-radius: 5px;
    background-color: #1c87c9;
    color: white;
    padding: 14px 20px;
    cursor: pointer;
    position: fixed;
  }
  .loginPopup {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 30px;
    margin-top: 200px;
  }
  .formContainer h2 {
    padding: 7px;
    font-size: 23px;
  }
  .formPopup {
    display: none;
    position: fixed;
    left: 50%;
    top: 28%;
    transform: translate(-50%, 5%);
    border: 3px solid #008A7C;
    z-index: 9;
    background-color: #00CA79;
    padding: 15px;
  }
  .formPopup2 {
    display: none;
    position: fixed;
    left: 50%;
    top: 28%;
    transform: translate(-50%, 5%);
    border: 3px solid #008A7C;
    z-index: 9;
    background-color: #00CA79;
    /* background-color: white; */
    padding: 15px;
  }
  .formContainer {
    max-width: 300px;
    padding: 20px;
    background-color: #fff;
  }
  .formContainer input[type=text],
  .formContainer input[type=password] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 20px 0;
    border: none;
    background: #eee;
  }
  .formContainer input[type=text]:focus,
  .formContainer input[type=password]:focus {
    background-color: #ddd;
    outline: none;
  }
  .formContainer .btn {
    padding: 12px 20px;
    border: none;
    background-color: #3f0071;
    color: #fff;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
    opacity: 0.8;
  }
  .formContainer .cancel {
    background-color: #cc0000;
  }
  .formContainer .btn:hover,
  .openButton:hover {
    opacity: 1;
  }
  .popupTextLink{
    color: #1240c9;
    font-weight: 500;
    font-size: 16px;
  }

.popupMsg{
  color: white;
  font-size: 18px;
}
.popupLink{
  color: #1240c9;
  font-size: 16px;
}
.popupMsg2{
  /* color: #091106; */
  color: #fff;
  font-size: 18px;
}
.popupLink2{
  color: #00CA79;
  font-size: 16px;
}
.popupLink3{
  color: red;
  font-size: 16px;
}
.error{
  color: red;
}
.center {
  text-align: center;
}
/* #myTable{
  padding: 20px;
} */
.getAllUsers{
  margin-top: 150px;
  align-items: center;
}